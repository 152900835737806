@import "minima-classic";

body {
      font-family: "Computer Modern", Times, serif;
}

blockquote {
  background: #ffffff;
  border-left: 0px solid #ccc;
  /*margin: 1.5em 10px;
  padding: 0.5em 10px;*/
  /*quotes: "\201C""\201D""\2018""\2019";*/
}
blockquote:before {
  color: #ffffff;
  /*content: open-quote;
  font-size: 3em;
  line-height: 0.1em;
  margin-right: 0.25em;
  vertical-align: -0.4em;*/
}
blockquote p {
  display: inline;
  color: #000000;
  font-style: normal;
  font-size: 16px;
  font-family: "Computer Modern", Times, serif;
  letter-spacing: normal;
}

ul {
  list-style-type: none;
}

.post-nav {
    /* Insert your custom styling here. Example:

       font-size: 14px;
       margin-bottom: 1em;
    */
    padding-right: $spacing-unit / 2;
    padding-left: $spacing-unit / 2;
    max-width: -webkit-calc(#{$content-width} - (#{$spacing-unit}));
    max-width:         calc(#{$content-width} - (#{$spacing-unit}));
    margin-right: auto;
    margin-left: auto;
    display: flex;
}
.post-nav div {
    /* flex-grow, flex-shrink, flex-basis */
    flex: 1 1 0;
}
.post-nav-next {
    text-align: right;
    padding-right: $spacing-unit / 2;
    padding-left: $spacing-unit / 2;
    max-width: -webkit-calc(#{$content-width} - (#{$spacing-unit}));
    max-width:         calc(#{$content-width} - (#{$spacing-unit}));
    margin-right: auto;
    margin-left: auto;
}
